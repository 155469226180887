import { format } from 'date-fns';
import { } from 'react-bootstrap';
import { trimInvalidDate } from './../../helpers/api/utils';
import DetailsHeader from 'components/DetailsHeader';
import Div from 'components/Div';
import TextLine from 'components/TextLine';
import Text from 'components/Text';
import CustomerIcon from 'components/icons/Customer';
import StatusBadge from 'components/StatusBadge';
import InfoPopover from 'components/InfoPopover';
import PopoverTooltip from 'components/PopoverTooltip';
import { ICompany } from './types';
import { useState } from 'react';

type CustomerDetailsHeaderProps = {
    company: ICompany;
};

const CustomerDetailsHeader = ({ company }: CustomerDetailsHeaderProps) => {
    const dateFormat = "MM/dd/yy";
    const logo_error = (e) => {
        e.target.style.display = 'none';
        e.target.nextElementSibling.style.display = 'block';
    }

    return (
        <DetailsHeader
            /* icon={<CustomerIcon style={{ height: '50px' }} />} */
            icon={
                (company.emailDomain === undefined || company.emailDomain === null || company.emailDomain === '') ?
                    <CustomerIcon fontSize={64} /> : <span><img className='copnay-icons' onError={logo_error} src={'https://logo.clearbit.com/' + company.emailDomain} /><CustomerIcon fontSize={64} style={{ display: 'none' }} /></span>}
            title={<DetailsHeader.Title title={company.legalName} />}
            pageTitle={company.legalName}
            subtitle={company.emailDomain}
            detailFields={
                <Text variant="plain">{company.companyType}</Text>
            }
            rightBox={
                <Div className="d-flex justify-content-end">
                    {/* <Div className="d-flex flex-column"> */}
                        {/* <TextLine
                            primary="Company Since:"
                            secondary={"10/22/2012"}
                        />
                        <Div>
                            <strong>Status: </strong>
                            <StatusBadge.Company status={"Active"} />
                        </Div>
                        <Div>
                            <strong>Last Event: </strong>
                            {"Company (10443720006383440) was added" ? (
                                <PopoverTooltip
                                    text={"Account"}
                                    placement="left"
                                />
                            ) : (
                                '-'
                            )}
                        </Div> */}

                        {/*  <TextLine
                            primary="Last Event:"
                            secondary={company.lastEventLog || '-'}
                        /> */}
                    {/* </Div> */}
                        <TextLine
                        primary={trimInvalidDate(company.createdDate && company.createdDate.split("T")[0]) == '' ? '' : "Last Updated date:"}
                        secondary={trimInvalidDate(company.createdDate && company.createdDate.split("T")[0])}
                        />
                </Div>
            }
        />
    );
};

export default CustomerDetailsHeader;
