import { Card, Col, Row, Button } from "react-bootstrap";
import DropdownItem from 'react-bootstrap/DropdownItem';
import DataTable from "react-data-table-component";
import { titleConvert } from "./../../../helpers/api/utils";
import { trimInvalidDate } from './../../../helpers/api/utils';
import Div from "../../../components/Div";
import PageTitle from "../../../components/PageTitle/index";
import CaseHistoryDetails from "./CasehistoryDetails/index";
import { useEffect, useState } from "react";
import { DEV_URL, rpost } from "../../../utils/requests";
import Spinner from "../../../components/Spinner";
import Text from '../../../components/Text';
import ThreeDotMenu from '../../../components/ThreeDotMenu';
const licenseColumns = [
    {
        name: "State",
        selector: (row) => row.stateCode,
        grow: 1,
        sortable: true,
    },
    {
        name: "Commodity",
        selector: (row) => titleConvert(row.commodity??''),
        grow: 1,
        sortable: true,
    },
    {
        name: "License Number",
        selector: (row) => row.licenseNumber,
        grow: 1,
        sortable: true,
    },
    {
        name: "License Start Date",
        selector: (row) => trimInvalidDate(row.licenseRegisteredDate.split("T")[0]),
        grow: 1,
        sortable: true,
    },
    {
        name: "License End Date",
        selector: (row) => trimInvalidDate(row.licenseExpiryDate.split("T")[0]),
        grow: 1,
        sortable: true,
    },
    {
        name: "License Type",
        selector: (row) => row.licenseType,
        grow: 1,
        sortable: true,
    },
    {
        name: "License Status",
        selector: (row) => row.licenseStatus,
        grow: 1,
        sortable: false,
    },
    {
        name: 'Action',
        grow: 0,
        selector: (row) => {
            var sourceBtn;
            var screenshotBtn;
            if (row.sourceUrl !== null && row.sourceUrl !== undefined && row.sourceUrl !== '') {
                sourceBtn = <DropdownItem onClick={(e) => {
                    const a = document.createElement('a');
                    a.href = row.sourceUrl;
                    a.target = '_blank';
                    a.click();
                }}><small>Source Site</small></DropdownItem>
            }
            if (row.screenshotUrl !== null && row.screenshotUrl !== undefined && row.screenshotUrl !== '') {
                screenshotBtn = <DropdownItem onClick={() => { ShowModal((row.screenshotUrl === null || row.screenshotUrl === undefined) ? '' : row.screenshotUrl) }}><small>Screenshot</small></DropdownItem>   
            }
            return <ThreeDotMenu className="text-center">
                {screenshotBtn}
                {sourceBtn}
            </ThreeDotMenu>
        } 

    }
];

const Casehistories = ({ licenseId, companyId }) => {
  const [caseHistoryData, setCaseHistoryData] = useState();
  const [loadingCaseHistoryData, setloadingCaseHistoryData] = useState();
  
  useEffect(async() => {
    setloadingCaseHistoryData(true);
    const caseHistoryDataResponse = await rpost(
      `${DEV_URL}/api/licenseCaseHistory/getall`,
      {
        PageNumber: 1,
        PageSize: 50,
        SearchTerm: "",
        companyId: companyId,
        licenseId: licenseId
      }
    );
    setCaseHistoryData(caseHistoryDataResponse);
    setloadingCaseHistoryData(false);
    return () => {
      setCaseHistoryData();
    }
  }, [])
  return (
    <>
      {caseHistoryData?.data?.licenseCaseHistory && <CaseHistoryDetails loadingCaseHistoryData={loadingCaseHistoryData} caseHistoryData={caseHistoryData.data.licenseCaseHistory} />}
    </>
  );
};
var ShowModal = (url) => { };
export const LicenseAndCaseHistories = ({ companyId, fetchedData }) => {
  const [companyLicenseData, setCompanyLicenseData] = useState();
  const [loadingCompanyDetailsData, setLoadingCompanyDetailsData] = useState();

    ShowModal = fetchedData.screenShotEvent;
  useEffect(async () => {
    setLoadingCompanyDetailsData(true);
    const companyLicensesDataResponse = await rpost(
      `${DEV_URL}/api/license/getall`,
      {
        PageNumber: 1,
        PageSize: 50,
        SearchTerm: "",
        companyId: companyId,
      }
    );
    

    //case History

    setCompanyLicenseData(companyLicensesDataResponse.data);
    setLoadingCompanyDetailsData(false);
    return () => {
      setCompanyLicenseData();
    };
  }, []);

  const customStyles = {
    header: {
      style: {
        backgroundColor: "#eef2f7",
        fontSize: "11px",
        color: "#474d56",
        minHeight: "28px"
      },
    },
    headRow: {
      style: {
        backgroundColor: "#eef2f7",
        fontSize: "11px",
        color: "#474d56",
        minHeight: "28px"
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          backgroundColor: "#eef2f7",
          fontSize: "11px",
          color: "#474d56",
          minHeight: "28px"
        },
      },
    },
    rows: {
      style: {
        minHeight: "28px",
        fontSize: "11px"
      }
    }
  }

  return (
    <span>
      <Div className="d-flex align-items-center justify-content-between">
        <PageTitle title={"Licenses"} />
      </Div>
      <Card className="">
        <Card.Body>
          <Row>
            <Col sm="12">
              {companyLicenseData === "Data not found" && <p>No data found</p>}
              {loadingCompanyDetailsData && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              )}
              {companyLicenseData?.license && (
                <DataTable
                  columns={licenseColumns}
                  data={companyLicenseData?.license}
                  customStyles={customStyles}
                  className="license-table"
                  expandableRows
                  expandableRowsComponent={(data) => {return <Casehistories licenseId={data.data.id} companyId={companyId} />}}
                />
              )}
              {/* {companyLicenseData?.license && (
                <LicenseDataTable
                  licenses={companyLicenseData?.license}
                  loading={loadingCompanyDetailsData}
                />
              )} */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* <Card className="">
        <Card.Body>
          <Row>
            <Col sm="12">
              {CaseHistory && (
                <DataTable columns={caseHistoryColumns} data={CaseHistory} customStyles={customStyles} />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
    </span>
  );
};
